.loading-logout {
  width: 30px;
  height: 30px;
  border: 2px solid transparent;
  border-top-color: #1d63dc;
  border-bottom-color: #1d63dc;
  border-radius: 50%;
  position: fixed;
  -webkit-animation: loader-rotate 1s linear infinite;
  animation: loader-rotate 1s linear infinite;
  margin: -15px 0 0 -15px;
  top: 50%;
  left: 50%;
  z-index: 9999;
}

.logout_modal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999
}

.logout-actions.modal-footer {
  flex-wrap: nowrap;
}

.logout-actions button {
  padding: 0;
  margin: 0;
  width: 50%;
  float: left;
  line-height: 57px;
}

.popup-messages .modal-dialog .logout-actions.modal-footer button:hover {
  color: #23527c;
}

.logout-actions.modal-footer button:last-child{
  border-left: 1px solid #e0e0e0;
}