.wrapper-menu.offcanvas.offcanvas-start {
    width: 285px;
    background-color: #262a41;
    font-size: 15px;
    color: #fff;
    z-index: 1200;
}

.wrapper-menu .offcanvas-header .title {
    color: #fff;
    margin-bottom: 10px;
}

.wrapper-menu .offcanvas-header .subtitle {
    color: #bdc5e6;
    font-size: 13px;
}
.wrapper-menu .offcanvas-body {
    padding: 0;
}

.wrapper-menu .item-menu {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
}

.wrapper-menu .item-menu .menu-item {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 40px;
    color: #d3d3d3;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: transparent;
}
.wrapper-menu .item-menu .menu-item.active,
.wrapper-menu .item-menu .menu-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.wrapper-menu .menu-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 11px 20px;
}

.wrapper-menu .menu-footer .pos-version {
    color: #626887;
    font-size: 14px;
}

.fixed-wrapper-header {
    height: 67px;
    width: 55px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 300;
    border: none !important;
    cursor: pointer;
}
.fixed-wrapper-header:before,
.fixed-wrapper-header:after {
    content: "";
    display: table;
}
.fixed-wrapper-header:after {
    clear: both;
}
.fixed-wrapper-header .header-left {
    float: left;
    width: 100%;
}
.fixed-wrapper-header .toggle-menu {
    display: block;
    width: 55px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('./images/icon-menu.svg');
    float: left;
    margin-top: 30px;
}
.fixed-wrapper-header .toggle-menu span {
    display: none;
}
.fixed-wrapper-header .toggle-menu:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    z-index: 200;
    -webkit-transition: 0.45s;
    -o-transition: 0.45s;
    transition: 0.45s;
}
.fixed-wrapper-header .toggle-menu.active:before {
    opacity: 1;
    visibility: visible;
    -webkit-transition: 0.45s;
    -o-transition: 0.45s;
    transition: 0.45s;
}
