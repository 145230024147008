.wrapper-order-right .block-actions .actions li:nth-child(4n) {
    clear: none !important;
}

.wrapper-order-right .block-actions .hidden-refund li {
    width: 25% !important;
}

.wrapper-order-right .block-actions .hidden-refund {
    justify-content: left !important;
}

.wrapper-order-right .block-actions .actions .btn-order-take-shipment .loader-product {
    border-bottom-color: #ffffff;
    border-top-color: #ffffff;
}

.wrapper-shipment-order {
    background-color: #f8f8f8;
    float: right;
    width: 100%;
    display: block;
    border-left: solid 1px #d5d9dd;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-top: 67px;
    display: -webkit-flex;
    display: flex;
    flex: 1 1 0;
    -webkit-flex-direction: column;
    flex-direction: column;
    z-index: 500;
}

.wrapper-shipment-order .block-title {
    text-align: center;
    line-height: 35px;
    padding: 23px 0 8px;
    border-bottom: solid 1px #d5d9dd;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
}

.wrapper-shipment-order .block-title .title {
    font-weight: normal;
    display: block;
    padding: 0 80px;
}

.wrapper-shipment-order .block-title .btn-cannel {
    color: #797979;
    font-size: 14px;
    font-weight: normal;
    display: block;
    position: absolute;
    left: 20px;
    height: 35px;
    box-shadow: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    z-index: 5;
}

.wrapper-shipment-order .block-title .price {
    font-size: 24px;
    font-weight: normal;
    float: right;
    position: absolute;
    top: 23px;
    right: 20px;
}

.wrapper-shipment-order .block-content {
    height: calc(100% - 79px);
    height: -moz-calc(100% - 79px);
    height: -webkit-calc(100% - 79px);
    height: -o-calc(100% - 79px);
    text-align: center;
    padding: 0;
    overflow-y: auto;
    background-color: #f8f8f8;
}

.wrapper-shipment-order .block-bottom {
    text-align: center;
    padding: 11px 14px;
    background-color: #f8f8f8;
}

.wrapper-shipment-order .block-bottom .btn {
    min-width: 175px;
    padding: 0 15px;
    margin: 0 4px;
    height: 57px;
    font-size: 19px;
}

.wrapper-shipment-order .label-checkbox {
    position: relative;
    font-weight: normal;
    display: block;
    cursor: pointer;
}

.wrapper-shipment-order .label-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.wrapper-shipment-order .label-checkbox input[type="checkbox"] + span {
    display: inline-block;
    width: 51px;
    height: 31px;
    border-radius: 31px;
    background-color: #dadada;
    position: relative;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    vertical-align: middle;
}

.wrapper-shipment-order .label-checkbox input[type="checkbox"] + span:before {
    content: "";
    display: block;
    width: 27px;
    height: 27px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.wrapper-shipment-order .label-checkbox input[type="checkbox"]:checked + span {
    background-color: #1d63dc;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.wrapper-shipment-order .label-checkbox input[type="checkbox"]:checked + span:before {
    left: 22px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.wrapper-shipment-order .label-checkbox input[type="checkbox"]:disabled + span {
    background-color: #f1f1f1;
    cursor: auto;
}

.wrapper-shipment-order .label-checkbox input[type="checkbox"]:disabled + span:before {
    background-color: #d0d0d0;
}

.wrapper-shipment-order .box-search {
    display: block;
    position: relative;
    float: right;
    width: 620px;
}

.body-touch .wrapper-shipment-order .box-search {
    width: 440px;
}

.wrapper-shipment-order .box-search .btn-search,
.wrapper-shipment-order .box-search .btn-remove {
    height: 54px;
    background-color: transparent;
    padding: 0;
    text-align: center;
    cursor: pointer;
    border: none;
    z-index: 3;
    line-height: 54px;
}

.wrapper-shipment-order .box-search .btn-search span,
.wrapper-shipment-order .box-search .btn-remove span {
    display: none;
}

.wrapper-shipment-order .box-search .input-search {
    display: block;
    width: 100%;
    height: 54px;
    border-radius: 5px;
    border: solid 1px #dfe1e4;
    padding-left: 52px;
    padding-right: 0;
    color: #1d1d1d;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 37px;
    box-shadow: none;
    position: relative;
    z-index: 5;
}

.wrapper-shipment-order .box-search .input-search::-moz-placeholder {
    color: #797979;
    opacity: 1;
}

.wrapper-shipment-order .box-search .input-search:-ms-input-placeholder {
    color: #797979;
}

.wrapper-shipment-order .box-search .input-search::-webkit-input-placeholder {
    color: #797979;
}

.wrapper-shipment-order .box-search .input-search.active {
    border: solid 1px #1e67e5;
}

.wrapper-shipment-order .box-search .btn-search {
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    z-index: 10;
}

.wrapper-shipment-order .box-search .btn-search:before {
    font-family: 'icomoon';
}

.wrapper-shipment-order .box-search .btn-search:before {
    content: "\e90f";
    color: #bbbbbe;
}

.wrapper-shipment-order .box-search .btn-remove {
    width: 30px;
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 10px;
    text-align: center;
    display: none;
    z-index: 10;
}

.wrapper-shipment-order .box-search .btn-remove:before {
    font-family: 'icomoon';
}

.wrapper-shipment-order .box-search .btn-remove:before {
    content: "\e904";
    color: #fff;
}

.wrapper-shipment-order .box-search .btn-remove:before {
    background-color: #dadada;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.wrapper-shipment-order .box-search .btn-remove.active {
    display: block;
}

.wrapper-shipment-order .block-search {
    padding: 14px 20px;
}

.wrapper-shipment-order .block-search:before,
.wrapper-shipment-order .block-search:after {
    content: "";
    display: table;
}

.wrapper-shipment-order .block-search:after {
    clear: both;
}

.wrapper-shipment-order .block-search .box-check {
    float: left;
    /*padding: 12px 0;*/
}

.wrapper-shipment-order .block-search .box-check .label-checkbox {
    margin-bottom: 0;
}

.wrapper-shipment-order .block-search .label-checkbox input[type="checkbox"] + span {
    margin-left: 5px;
}

.wrapper-shipment-order .product-field-qty .box-field-qty {
    position: relative;
    border-radius: 5px;
    padding: 0;
    margin: auto;
    width: 170px;
    border: solid 1px #e6e6e6;
}

.wrapper-shipment-order .product-field-qty .box-field-qty.active {
    border: solid 1px #1e67e5;
}

.wrapper-shipment-order .product-field-qty .form-control {
    height: 48px;
    display: block;
    border-radius: 0;
    background-color: transparent;
    font-size: 15px;
    color: #1d63dc;
    text-align: center;
    width: 100%;
    box-shadow: none;
    border: none;
    padding: 0;
    line-height: 48px;
    cursor: pointer;
}

.wrapper-shipment-order .product-field-qty .btn-number {
    position: absolute;
    top: 0px;
    line-height: 48px;
    text-align: center;
    color: #1d1d1d;
    display: block;
    width: 48px;
    z-index: 5;
}

.wrapper-shipment-order .product-field-qty .btn-number:before {
    content: "";
    display: block;
    width: 1px;
    height: 38px;
    position: absolute;
    top: 6px;
    background-color: #dfe1e4;
}

.wrapper-shipment-order .product-field-qty .btn-number.disabled {
    background-color: #f4f4f4;
    color: #9b9b9b;
}

.wrapper-shipment-order .product-field-qty .qtyminus {
    left: 0;
    border-radius: 5px 0 0 5px;
}

.wrapper-shipment-order .product-field-qty .qtyminus:before {
    right: 0;
}

.wrapper-shipment-order .product-field-qty .qtyplus {
    right: 0;
    border-radius: 0 5px 5px 0;
}

.wrapper-shipment-order .product-field-qty .qtyplus:before {
    left: 0;
}

.wrapper-shipment-order .table-order {
    line-height: 22px;
    margin: 0;
    background-color: #fff;
}

.wrapper-shipment-order .table-order p {
    margin-bottom: 5px;
}

.wrapper-shipment-order .table-order b {
    font-weight: 500;
}

.wrapper-shipment-order .table-order tr th,
.wrapper-shipment-order .table-order tr td {
    text-align: center;
    white-space: nowrap;
    padding: 12px 20px;
    width: 1%;
}

.wrapper-shipment-order .table-order tr .t-product {
    text-align: left;
    padding-left: 0;
    width: 50%;
}

.wrapper-shipment-order .table-order tr .t-qty,
.wrapper-shipment-order .table-order tr .t-qtyrefund {
    width: 25%;
}

.wrapper-shipment-order .table-order thead tr .t-col,
.wrapper-shipment-order .table-order tbody tr .t-col,
.wrapper-shipment-order .table-order tfoot tr .t-col {
    padding-left: 10px;
    padding-right: 10px;
    border: none;
}

.wrapper-shipment-order .table-order thead tr .t-rowtotal,
.wrapper-shipment-order .table-order tbody tr .t-rowtotal,
.wrapper-shipment-order .table-order tfoot tr .t-rowtotal {
    padding-right: 0;
}

.body-touch .wrapper-shipment-order .table-order thead tr .t-price,
.body-touch .wrapper-shipment-order .table-order tbody tr .t-price,
.body-touch .wrapper-shipment-order .table-order tfoot tr .t-price,
.body-touch .wrapper-shipment-order .table-order thead tr .t-fpt,
.body-touch .wrapper-shipment-order .table-order tbody tr .t-fpt,
.body-touch .wrapper-shipment-order .table-order tfoot tr .t-fpt,
.body-touch .wrapper-shipment-order .table-order thead tr .t-tax,
.body-touch .wrapper-shipment-order .table-order tbody tr .t-tax,
.body-touch .wrapper-shipment-order .table-order tfoot tr .t-tax,
.body-touch .wrapper-shipment-order .table-order thead tr .t-discount,
.body-touch .wrapper-shipment-order .table-order tbody tr .t-discount,
.body-touch .wrapper-shipment-order .table-order tfoot tr .t-discount {
    display: none;
}

.wrapper-shipment-order .table-order thead tr th,
.wrapper-shipment-order .table-order thead tr td {
    font-size: 15px;
    font-weight: 500;
    border-bottom: solid 1px #dfe1e4;
}

.wrapper-shipment-order .table-order thead tr .t-product {
    white-space: nowrap;
}

.wrapper-shipment-order .table-order thead tr .t-col {
    border-bottom: solid 1px #dfe1e4;
}

.wrapper-shipment-order .table-order tbody tr th,
.wrapper-shipment-order .table-order tbody tr td {
    font-weight: normal;
    border-top: solid 1px #dfe1e4;
}

.wrapper-shipment-order .table-order tbody tr .t-product {
    white-space: normal;
    font-size: 14px;
}

.wrapper-shipment-order .table-order thead .t-return,
.wrapper-shipment-order .table-order tbody .t-return {
    max-width: 200px;
    width: 100%;
}

.wrapper-shipment-order .table-order tbody .t-return {
    color: #797979;
}

.wrapper-shipment-order .table-order tbody .t-qty {
    line-height: 29px;
    width: 25%;
}

.wrapper-shipment-order .table-order tbody .t-bundle-product {
    color: #7f7f7f;
    padding-top: 0;
    border-top: none;
    /*padding-right: 0;*/
}

.wrapper-shipment-order .table-order tfoot th,
.wrapper-shipment-order .table-order tfoot td {
    border: none;
    background-color: #ededed;
}

.wrapper-shipment-order .table-order tfoot tr {
    background-color: #ededed;
}

.wrapper-shipment-order .table-order .t-qtyrefund .t-alert {
    max-width: 200px;
    white-space: normal;
    color: #797979;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
}

.wrapper-shipment-order .table-order .title {
    padding-top: 5px;
}

.wrapper-shipment-order .table-order .option {
    color: #797979;
    overflow: hidden;
    position: relative;
    line-height: 20px;
    max-height: 40px;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
}

.wrapper-shipment-order .table-order .option:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
}

.wrapper-shipment-order .table-order .option:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 20px;
    margin-top: 0.2em;
    background: #fff;
}

.wrapper-shipment-order .table-order .t-rowtotal {
    /*display: none;*/
}

.wrapper-shipment-order .table-order .t-rowtotal .hidden-desktop {
    display: none;
}

.body-touch .wrapper-shipment-order .table-order .t-rowtotal .hidden-desktop {
    display: block;
}

.body-touch .wrapper-shipment-order .table-order .t-rowtotal {
    display: table-cell;
    text-align: right;
}

.body-touch .wrapper-shipment-order .table-order .t-rowtotal .hidden-mobile {
    display: none;
}

.wrapper-shipment-order .search-suggest {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 0 0 5px 5px;
    background-color: #f4f4f4;
    border: solid 1px #dfe1e4;
    z-index: 100;
    font-size: 14px;
    color: #797979;
    padding: 0 25px;
    margin-top: -1px;
    max-height: 390px;
    z-index: 2;
}

.wrapper-shipment-order .search-suggest .suggest-count {
    line-height: 40px;
    border-bottom: solid 1px #dfe1e4;
    margin-bottom: -1px;
}

.wrapper-shipment-order .search-suggest .suggest-item {
    display: block;
    border-top: solid 1px #dfe1e4;
    line-height: 22px;
    padding: 10px 0 8px;
}

.wrapper-shipment-order .search-suggest .title,
.wrapper-shipment-order .search-suggest .subtitle {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
}

.wrapper-shipment-order .search-suggest .title {
    color: #1d1d1d;
}

.wrapper-shipment-order .block-refund-adjustments,
.wrapper-shipment-order .block-refund-totals,
.wrapper-shipment-order .block-refund-point {
    max-width: 554px;
    margin: 20px auto;
}

.wrapper-shipment-order .block-refund-adjustments .box-title,
.wrapper-shipment-order .block-refund-totals .box-title,
.wrapper-shipment-order .block-refund-point .box-title {
    display: block;
    border-bottom: solid 1px #dfe1e4;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    padding: 11px 30px;
    text-transform: capitalize;
}

.wrapper-shipment-order .block-refund-adjustments {
    margin-top: 0;
    padding-top: 20px;
}

.wrapper-shipment-order .block-refund-adjustments .box {
    background-color: #ffffff;
}

.wrapper-shipment-order .block-refund-adjustments .box-content {
    padding: 10px 30px;
}

.wrapper-shipment-order .block-refund-adjustments .form-group {
    margin-bottom: 10px;
}

.wrapper-shipment-order .block-refund-adjustments .form-group:before,
.wrapper-shipment-order .block-refund-adjustments .form-group:after {
    content: "";
    display: table;
}

.wrapper-shipment-order .block-refund-adjustments .form-group:after {
    clear: both;
}

.wrapper-shipment-order .block-refund-adjustments label {
    display: block;
    float: left;
    line-height: 30px;
    padding: 10px 0;
    margin: 0;
    color: #1d1d1d;
    font-size: 14px;
    font-weight: normal;
    width: calc(100% - 220px);
    width: -moz-calc(100% - 220px);
    width: -webkit-calc(100% - 220px);
    width: -o-calc(100% - 220px);
    text-align: left;
}

.wrapper-shipment-order .block-refund-adjustments .label {
    display: block;
    width: 30px;
    text-align: left;
    line-height: 50px;
    color: #797979;
    font-size: 12px;
    font-weight: normal;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px 5px 0 0;
    padding-left: 5px;
}

.wrapper-shipment-order .block-refund-adjustments .form-control {
    height: 50px;
    border-radius: 5px;
    border: solid 1px #e6e6e6;
    display: block;
    width: 100%;
    padding-right: 30px;
    text-align: right;
    box-shadow: none;
    color: #1d63dc;
    font-size: 15px;
    line-height: 50px;
    padding-bottom: 0;
    padding-top: 0;
}

.wrapper-shipment-order .block-refund-adjustments .control {
    float: right;
    width: 200px;
    position: relative;
    cursor: pointer;
}

.wrapper-shipment-order .block-refund-adjustments .cas {
    clear: both;
    color: #d0021b;
    text-align: right;
    line-height: 22px;
    font-size: 14px;
}

.wrapper-shipment-order .block-refund-point {
    margin-top: 0;
    padding-top: 20px;
}

.wrapper-shipment-order .block-refund-point .box {
    background-color: #ffffff;
}

.wrapper-shipment-order .block-refund-point .box-content {
    padding: 10px 30px;
}

.wrapper-shipment-order .block-refund-point .form-group {
    margin-bottom: 10px;
}

.wrapper-shipment-order .block-refund-point .form-group:before,
.wrapper-shipment-order .block-refund-point .form-group:after {
    content: "";
    display: table;
}

.wrapper-shipment-order .block-refund-point .form-group:after {
    clear: both;
}

.wrapper-shipment-order .block-refund-point label {
    display: block;
    float: left;
    line-height: 30px;
    padding: 10px 0;
    margin: 0;
    color: #1d1d1d;
    font-size: 14px;
    font-weight: normal;
    width: calc(100% - 220px);
    width: -moz-calc(100% - 220px);
    width: -webkit-calc(100% - 220px);
    width: -o-calc(100% - 220px);
    text-align: left;
}

.wrapper-shipment-order .block-refund-point .label {
    display: block;
    width: 45px;
    text-align: left;
    line-height: 50px;
    color: #797979;
    font-size: 12px;
    font-weight: normal;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px 5px 0 0;
    padding-left: 5px;
}

.wrapper-shipment-order .block-refund-point .form-control {
    height: 50px;
    border-radius: 5px;
    border: solid 1px #e6e6e6;
    display: block;
    width: 100%;
    padding-right: 45px;
    text-align: right;
    box-shadow: none;
    color: #1d63dc;
    font-size: 15px;
    line-height: 50px;
    padding-bottom: 0;
    padding-top: 0;
}

.wrapper-shipment-order .block-refund-point .control {
    float: right;
    width: 200px;
    position: relative;
    cursor: pointer;
}

.wrapper-shipment-order .block-refund-point .cas {
    clear: both;
    color: #d0021b;
    text-align: right;
    line-height: 22px;
    font-size: 14px;
}

.wrapper-shipment-order .block-refund-totals {
    background-color: #ffffff;
}

.wrapper-shipment-order .block-refund-totals .box-content {
    padding: 20px;
}

.wrapper-shipment-order .block-refund-totals ul {
    padding-bottom: 5px;
}

.wrapper-shipment-order .block-refund-totals li {
    display: table;
    line-height: 30px;
    padding: 5px 0;
}

.wrapper-shipment-order .block-refund-totals .label {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    color: #1d1d1d;
    font-size: 14px;
    padding: 0;
    font-weight: normal;
    text-align: left;
    line-height: 30px;
}

.wrapper-shipment-order .block-refund-totals .value {
    display: table-cell;
    vertical-align: top;
    width: 1%;
    white-space: nowrap;
    font-size: 14px;
}

.wrapper-shipment-order .block-refund-totals .total-bold {
    font-weight: 500;
}

.wrapper-shipment-order .block-refund-totals .total-bold .label {
    font-weight: 500;
}

.wrapper-shipment-order .block-refund-totals .total {
    border-top: solid 1px #dfe1e4;
    font-weight: 500;
    margin-top: 5px;
}

.wrapper-shipment-order .block-refund-totals .total .label {
    font-weight: 500;
}

.wrapper-shipment-order .block-refund-totals .total-padding {
    padding-left: 10px
}

.back-refund-order {
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 55px;
    height: 25px;
    line-height: 25px;
    float: left;
    margin-top: 30px;
    font-size: 18px;
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 200;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.popup-calculator .check-price label {
    display: block;
    font-weight: normal;
    color: #aeaeae;
    font-size: 20px;
    padding: 0;
    margin: 0;
    position: relative;
    padding: 0 20px 20px;
}

.popup-calculator .check-price input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.popup-calculator .check-price input[type="checkbox"] + span {
    display: block;
    width: 100%;
    height: 43px;
    border-radius: 7px;
    border: solid 1px #d5d5d5;
    position: relative;
}

.popup-calculator .check-price input[type="checkbox"]:checked + span:before {
    border-radius: 0px 5px 5px 0px;
}

.popup-calculator .check-price input[type="checkbox"] + span:before {
    content: "";
    display: block;
    width: 50%;
    height: 42px;
    border-radius: 5px 0px 0px 5px;
    background-color: #0174dc;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 3;
}

.popup-calculator .check-price input[type="checkbox"] ~ .price,
.popup-calculator .check-price input[type="checkbox"] ~ .percent {
    display: block;
    text-align: center;
    width: calc(50% - 20px);
    width: -moz-calc(50% - 20px);
    width: -webkit-calc(50% - 20px);
    width: -o-calc(50% - 20px);
    height: 43px;
    border-radius: 25px;
    line-height: 43px;
    position: absolute;
    top: 0;
    z-index: 5;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.popup-calculator .check-price input[type="checkbox"] ~ .price {
    left: 20px;
    color: #fff;
}

.popup-calculator .check-price input[type="checkbox"] ~ .percent {
    right: 20px;
}

.popup-calculator .check-price input[type="checkbox"]:checked ~ .price {
    color: #aeaeae;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.popup-calculator .check-price input[type="checkbox"]:checked ~ .percent {
    color: #fff;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.popup-calculator .check-price input[type="checkbox"]:checked + span:before {
    left: 50%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.payment-full-amount .info .not-allowed-check-box {
    cursor: not-allowed;
}
.payment-full-amount .info .title {
    display: block;
    height: 22px;
    line-height: 22px;
}

.payment-full-amount .info .additional-info {
    display: block;
    font-size: 11px;
    line-height: 12px;
    padding: 1px 0;
}

.block-refund-payment .add-payment {
    line-height: 50px;
    padding: 6px 0;
}

.block-refund-payment .add-payment .add-cash {
    height: 50px;
    line-height: 50px;
}

.block-refund-payment .add-payment .label {
    margin-left: 0;
}

.add-payment-refund {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    display: -webkit-flex;
    display: flex;
    flex: 1 1 0;
    margin: 0 -5px;
    padding-bottom: 18px;
}

.add-payment-refund .item {
    display: block;
    border-radius: 10px;
    background-color: #ffffff;
    border: solid 1px #e5e5e5;
    width: calc(25% - 10px);
    width: -moz-calc(25% - 10px);
    width: -webkit-calc(25% - 10px);
    width: -o-calc(25% - 10px);
    margin: 0 5px 5px;
    padding: 19px 10px 13px;
    text-align: center;
    cursor: pointer;
}

.add-payment-refund .title {
    display: block;
    line-height: 24px;
    padding: 4px 0;
}

.add-payment-refund .item .additional-info {
    display: block;
    font-size: 11px;
    line-height: 12px;
    padding: 1px 0;
}

.add-payment-refund .img {
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 4px;
}

.wrapper-shipment-order .payment-total {
    margin-bottom: 25px;
    min-width: 210px;
    max-width: 650px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
}

.wrapper-shipment-order .payment-total li {
    display: block;
}

.wrapper-shipment-order .payment-total li:before,
.wrapper-shipment-order .payment-total li:after {
    content: "";
    display: table;
}

.wrapper-shipment-order .payment-total li:after {
    clear: both;
}

.wrapper-shipment-order .payment-total .label,
.wrapper-shipment-order .payment-total .value {
    display: inline-block;
    font-weight: normal;
    line-height: 43px;
    color: #1d1d1d;
    padding: 0;
    vertical-align: text-top;
}

.wrapper-shipment-order .payment-total .label {
    font-size: 15px;
    display: block;
    color: #9b9b9b;
}

.wrapper-shipment-order .payment-total .value {
    font-size: 36px;
    color: #1d1d1d;
}

.wrapper-shipment-order .payment-logo {
    padding-top: 30px;
}

.wrapper-shipment-order .payment-amount {
    height: 108px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #d9d9d9;
    line-height: 106px;
    max-width: 535px;
    margin: 30px auto 17px;
    padding: 0 28px 0 17px;
    cursor: pointer;
}

.wrapper-shipment-order .payment-amount .label {
    display: block;
    font-size: 15px;
    color: #797979;
    padding: 0;
    line-height: 106px;
    font-weight: normal;
    float: left;
    width: 90px;
    text-align: left;
}

.wrapper-shipment-order .payment-amount .value {
    font-size: 36px;
    float: right;
    max-width: calc(100% - 90px);
    max-width: -moz-calc(100% - 90px);
    max-width: -webkit-calc(100% - 90px);
    max-width: -o-calc(100% - 90px);
    text-align: right;
}

.wrapper-shipment-order .paypal-by {
    max-width: 535px;
    margin: 0 auto 15px;
}

.wrapper-shipment-order .paypal-by .validation-advice {
    position: static;
    bottom: -19px;
    right: 0;
    line-height: 16px;
    font-size: 12px;
    color: #d0021b;
}

.wrapper-shipment-order .paypal-by .validation-advice .dropdown-toggle {
    display: block;
    width: 17px;
    height: 17px;
    background-color: #dc3636;
    cursor: pointer;
    text-align: center;
    line-height: 17px;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    bottom: 15px;
}

.wrapper-shipment-order .paypal-by .validation-advice .dropdown-toggle:before {
    font-family: 'icomoon';
}

.wrapper-shipment-order .paypal-by .validation-advice .dropdown-toggle:before {
    content: "\e911";
    color: #d0021b;
}

.wrapper-shipment-order .paypal-by .validation-advice .dropdown-toggle:before {
    color: #fff;
    font-size: 10px;
    font-weight: normal;
}

.wrapper-shipment-order .paypal-by .validation-advice .popover {
    border-radius: 2px;
    background-color: transparent;
    position: absolute;
    right: 0 !important;
    left: auto !important;
    color: #d0021b;
    font-size: 14px;
    padding: 0;
    line-height: 20px;
    box-shadow: none;
    border: none;
    white-space: nowrap;
    margin-top: 19px;
    width: auto;
    max-width: none;
}

.wrapper-shipment-order .paypal-by .validation-advice .popover:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 6px 5px;
    border-color: transparent transparent #d94b4b transparent;
    position: absolute;
    right: 8px;
    top: -6px;
    display: none;
}

.wrapper-shipment-order .paypal-by .validation-advice .popover .popover-content {
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.wrapper-shipment-order .paypal-by .validation-advice .popover .arrow {
    display: none;
}

.wrapper-shipment-order .paypal-by .tab-pane {
    background-color: #fff;
    padding: 20px;
}

.wrapper-shipment-order .paypal-by label {
    display: block;
    font-weight: normal;
    color: #797979;
    line-height: 22px;
    font-size: 14px;
    padding: 0;
    margin-bottom: 3px;
    text-align: left;
}

.wrapper-shipment-order .paypal-by .form-group {
    margin-bottom: 22px;
    position: relative;
}

.wrapper-shipment-order .paypal-by .form-group.last {
    margin-bottom: 0;
}

.wrapper-shipment-order .paypal-by .form-control {
    display: block;
    width: 100%;
    height: 30px;
    border-radius: 0;
    background-color: #ffffff;
    border-width: 0 0 1px;
    border-bottom: solid 1px #d9d9d9;
    font-size: 14px;
    color: #797979;
    box-shadow: none;
    text-align: left;
    line-height: 42px;
    padding: 0 11px;
    cursor: pointer;
    line-height: 22px;
    padding-top: 1px;
    padding-bottom: 6px;
    padding-left: 0;
}

.wrapper-shipment-order .paypal-by .form-control::-moz-placeholder {
    color: #797979;
    opacity: 1;
}

.wrapper-shipment-order .paypal-by .form-control:-ms-input-placeholder {
    color: #797979;
}

.wrapper-shipment-order .paypal-by .form-control::-webkit-input-placeholder {
    color: #797979;
}

.wrapper-shipment-order .paypal-by .toggle {
    display: block;
    font-weight: normal;
    padding: 0;
    margin: 0 auto 16px;
    width: 300px;
    height: 39px;
    border-radius: 25px;
    border: solid 1px #d5d5d5;
    position: relative;
    font-size: 15px;
    cursor: pointer;
}

.wrapper-shipment-order .paypal-by .toggle:before {
    content: "";
    display: block;
    width: 150px;
    height: 39px;
    border-radius: 25px;
    background-color: #1e67e5;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.wrapper-shipment-order .paypal-by .toggle .text-left,
.wrapper-shipment-order .paypal-by .toggle .text-right {
    display: block;
    width: 150px;
    line-height: 39px;
    height: 39px;
    color: #aeaeae;
    text-align: center;
    padding: 0;
    background-color: transparent;
    border-radius: 25px;
    position: absolute;
    top: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 6;
}

.wrapper-shipment-order .paypal-by .toggle .text-left {
    left: 0;
}

.wrapper-shipment-order .paypal-by .toggle .text-right {
    right: 0;
    color: #fff;
}

.wrapper-shipment-order .paypal-by input.checkpaypal-by {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.wrapper-shipment-order .paypal-by input.checkpaypal-by ~ .by-card {
    display: none;
}

.wrapper-shipment-order .paypal-by input.checkpaypal-by ~ .by-email {
    display: block;
}

.wrapper-shipment-order .paypal-by input.checkpaypal-by:checked + .toggle:before {
    right: 150px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.wrapper-shipment-order .paypal-by input.checkpaypal-by:checked + .toggle .text-left {
    color: #fff;
}

.wrapper-shipment-order .paypal-by input.checkpaypal-by:checked + .toggle .text-right {
    color: #aeaeae;
}

.wrapper-shipment-order .paypal-by input.checkpaypal-by:checked ~ .by-card {
    display: block;
}

.wrapper-shipment-order .paypal-by input.checkpaypal-by:checked ~ .by-email {
    display: none;
}

.wrapper-shipment-order .paypal-by .col-xs-8,
.wrapper-shipment-order .paypal-by .col-xs-2 {
    padding: 0 4px;
}

.wrapper-shipment-order .paypal-by .col-xs-2 {
    width: 108px;
}

.wrapper-shipment-order .paypal-by .col-xs-8 {
    width: calc(100% - 216px);
    width: -moz-calc(100% - 216px);
    width: -webkit-calc(100% - 216px);
    width: -o-calc(100% - 216px);
}

.wrapper-shipment-order .paypal-by .row {
    margin: 0 -4px;
}

.wrapper-shipment-order .paypal-by .button-swipe {
    margin: -20px -20px 20px;
    display: block;
}

.wrapper-shipment-order .paypal-by .button-swipe .btn {
    display: block;
    height: 43px;
    background-color: #ececec;
    width: 100%;
    border-radius: 5px 5px 0 0;
    font-size: 15px;
    color: #4a4a4a;
    font-weight: normal;
}

.block-refund-success {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 75px;
    font-size: 16px;
}

.block-refund-success .box-email {
    position: relative;
    margin-top: 50px;
    margin-bottom: 40px;
    text-align: left;
}

.block-refund-success .box-email .form-group {
    position: relative;
    margin-bottom: 20px;
}

.block-refund-success .box-email .control {
    position: relative;
    margin-top: 14px;
}

.block-refund-success .box-email label {
    display: block;
    text-align: left;
    font-weight: normal;
    line-height: 26px;
    margin-bottom: 0;
    font-size: 15px;
    color: #1d1d1d;
    padding: 0;
}

.block-refund-success .box-email .note {
    color: #9b9b9b;
    font-size: 14px;
    display: block;
}

.block-refund-success .box-email .form-control {
    display: block;
    width: 100%;
    height: 57px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #d9d9d9;
    box-shadow: none;
    font-size: 15px;
    color: #1d1d1d;
    padding-right: 115px;
}

.block-refund-success .box-email .btn {
    display: block;
    height: 44px;
    border-radius: 4px;
    background-color: #1e67e5;
    width: 102px;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 3;
    padding-left: 3px;
    padding-right: 3px;
}

.block-refund-success .validation-text {
    display: block;
    color: #d0021b;
    line-height: 22px;
    text-align: left;
    font-size: 14px;
}

.popup-messages .refund-confirmation-text {
    height: 139px;
    background-color: #ffffff;
    border-radius: 0;
    border-width: 0 0 1px;
    border-bottom: solid 1px #dfe1e4;
    box-shadow: none;
    font-size: 14px;
    color: #7c7d80;
    padding: 17px 20px;
    margin-top: 20px;
    line-height: 25px;
}

.popup-messages .refund-confirmation-text:focus {
    border: solid 1px #1e67e5;
}

.popup-messages-refund .modal-content {
    background-color: #f8f8f8;
}

.popup-messages-refund .modal-header {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    padding: 21px;
}

.popup-messages-refund .modal-header .title {
    font-size: 17px;
    font-weight: normal;
    margin: 0;
    line-height: 24px;
}

.popup-messages-refund .modal-body {
    padding: 20px 20px 25px;
}

/** checkout page **/
.wrapper-payment .block-content.has-create-shipment-button {
    height: calc(100% - 90px - 80px);
}

.wrapper-payment .shipping-toggle-button {
    padding-right: 18px;
}

.wrapper-payment .shipping-toggle-button .info {
    margin-top: 8px;
    width: 100%;
}

.wrapper-payment .shipping-toggle-button .info .price {
    width: 100%;
}

.wrapper-payment .shipping-toggle-button .price span.label {
    margin-left: 0;
    font-weight: 600;
}

.wrapper-payment .shipping-toggle-button .checkbox {
    margin: 0;
}

.wrapper-payment .shipping-toggle-button .checkbox label {
    position: relative;
}

.wrapper-payment .shipping-toggle-button .checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
}

.wrapper-payment .shipping-toggle-button .checkbox input[type="checkbox"] + span {
    display: block;
    width: 51px;
    height: 31px;
    border-radius: 31px;
    background-color: rgba(255, 255, 255, 0);
    border: solid 1px #e6e6e6;
    position: relative;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 1;
}

.wrapper-payment .shipping-toggle-button .checkbox input[type="checkbox"] + span span {
    display: none;
}

.wrapper-payment .shipping-toggle-button .checkbox input[type="checkbox"] + span:before {
    content: '';
    display: block;
    width: 28px;
    height: 29px;
    background-color: #ffffff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0px;
    left: 1px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 5;
    border-radius: 100%;
}

.wrapper-payment .shipping-toggle-button .checkbox input[type="checkbox"]:checked + span {
    background-color: #1e67e5;
    border-color: #1e67e5;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.wrapper-payment .shipping-toggle-button .checkbox input[type="checkbox"]:checked + span:before {
    left: 21px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}