.custom-price-discount-amount{
    height: 60px;
    display: block;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    border: none;
    padding: 0 3px;
    box-shadow: none;
    pointer-events: none;
    font-size: 36px;
    color: #1d1d1d;
    text-align: right;
    font-weight: normal;
    outline: none;
}
.set-custom-price-popup {
    height: auto;
}
.set-custom-price-popup .check-price label{
    display: block;
    font-weight: normal;
    color: #aeaeae;
    font-size: 20px;
    margin: 0;
    position: relative;
    padding: 0px 20px;
}
.set-custom-price-popup .check-price input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}
.set-custom-price-popup .check-price input[type="checkbox"] + span {
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 7px;
    border: solid 1px #1d63dc;
    position: relative;
}
.set-custom-price-popup .check-price input[type="checkbox"] + span:before {
    content: "";
    display: block;
    width: 50%;
    height: 39px;
    border-radius: 5px 0px 0px 5px;
    background-color: #0174dc;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 3;
}
.set-custom-price-popup .check-price input[type="checkbox"]:checked + span:before{
    left: 50%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.set-custom-price-popup .check-price input[type="checkbox"]:checked + span:before {
    border-radius: 0px 5px 5px 0px;
}
.set-custom-price-popup .check-price input[type="checkbox"] ~ .percent {
    left: 20px;
    color: #fff;
    display: block;
    text-align: center;
    width: calc(50% - 20px);
    height: 40px;
    border-radius: 25px;
    line-height: 40px;
    position: absolute;
    top: 0;
    z-index: 5;
    transition: 0.2s;
    font-size: 18px;
}
.set-custom-price-popup .check-price input[type="checkbox"] ~ .price {
    right: 20px;
    display: block;
    text-align: center;
    width: calc(50% - 20px);
    height: 40px;
    border-radius: 25px;
    line-height: 40px;
    position: absolute;
    top: 0;
    z-index: 5;
    transition: 0.2s;
    font-size: 18px;
    color: #1d63dc;
}
.set-custom-price-popup .check-price input[type="checkbox"]:checked ~ .percent {
    color: #1d63dc;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}
.set-custom-price-popup .check-price input[type="checkbox"]:checked ~ .price {
    color: #fff;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}
ul.nav-tabs,
#custom-price-container ul.nav-pills{
    margin-bottom: 15px;
    border-radius: 6px 6px 0px 0px;
    display: flex;
}
ul.nav-tabs li{
    width: 50%;
    margin: 0;
}
.nav-tabs>li>button{
    width: 100%;
    color: #1d1d1d;
}
#custom-price-container ul.nav-pills li{
    width: 50%;
    margin: 0;
}
#custom-price-container .nav-pills>li>a{
    background-color: #eee;
    color: #1d1d1d;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    outline: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    border: none;
}
.nav-tabs>li.active>button,
.nav-tabs>li.active>button:focus,
.nav-tabs>li.active>button:hover,
#custom-price-container .nav-pills>li.active>a,
#custom-price-container .nav-pills>li.active>a:focus,
#custom-price-container .nav-pills>li.active>a:hover{
    background-color: #fff;
    text-align: center;
    color: #1d1d1d;
    line-height: 25px;
    font-size: 16px;
    outline: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-tabs>li.active>.custom-price-container-tab-custom_discount_price,
.nav-tabs>li.active>.custom-price-container-tab-custom_discount_price:focus,
.nav-tabs>li.active>.custom-price-container-tab-custom_discount_price:hover{
    border-radius: 6px 0px 0px 0px;
}
.nav-tabs>li.active>.custom-price-container-tab-custom_price,
.nav-tabs>li.active>.custom-price-container-tab-custom_price:focus,
.nav-tabs>li.active>.custom-price-container-tab-custom_price:hover{
    border-radius: 0px 6px 0px 0px;
}
#custom-price-container .nav-pills>li.active>#custom-price-container-tab-custom_discount_price,
#custom-price-container .nav-pills>li.active>#custom-price-container-tab-custom_discount_price:focus,
#custom-price-container .nav-pills>li.active>#custom-price-container-tab-custom_discount_price:hover{
    border-radius: 6px 0px 0px 0px;
}
#custom-price-container .nav-pills>li.active>#custom-price-container-tab-custom_price,
#custom-price-container .nav-pills>li.active>#custom-price-container-tab-custom_price:focus,
#custom-price-container .nav-pills>li.active>#custom-price-container-tab-custom_price:hover{
    border-radius: 0px 6px 0px 0px;
}