.wrapper-login {
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    flex: 1 1 0;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.wrapper-login .form-login {
    width: 400px;
    margin: 0 auto;
    padding: 20px 0;
}
.wrapper-login .logo {
    display: block;
    text-align: center;
    width: 400px;
    margin: 0 auto  60px;
}
.wrapper-login .page-title {
    font-size: 24px;
    margin: 0 0 60px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
}


.wrapper-login .form-control {
    width: 100%;
    border-width: 0 0 1px;
    border-radius: 0;
    padding-left: 0;
    height: 43px;
    padding-right: 0;
}
.wrapper-login .form-control:focus {
    box-shadow: none;
    border-bottom: solid 1px #4786ff;
}
.wrapper-login select,
.wrapper-login select.form-control {
    padding-right: 20px;
    background-position: center right;
    background-size: 16px auto;
}
.wrapper-login .form-group {
    position: relative;
    margin-bottom: 34px;
}


.wrapper-login label {
    display: block;
}
.wrapper-login label span {
    display: none;
}
.wrapper-login .group-username label,
.wrapper-login .group-shop label,
.wrapper-login .group-password label {
    width: 54px;
    height: 43px;
    position: absolute;
    top: 1px;
    left: 0;
    text-align: left;
    line-height: 35px;
}
.wrapper-login .group-username .form-control,
.wrapper-login .group-password .form-control,
.wrapper-login .group-shop .form-control{
    padding-left: 33px;
}
.wrapper-login .group-username label {
    font-size: 24px;
}
.wrapper-login .group-username label:before {
    font-family: 'icomoon';
}
.wrapper-login .group-username label:before {
    content: "\e912";
    color: #dfe1e4;
}
.wrapper-login .group-password label {
    font-size: 22px;
}
.wrapper-login .group-password label:before {
    font-family: 'icomoon';
}
.wrapper-login .group-password label:before {
    content: "\e909";
    color: #dfe1e4;
}
.wrapper-login .group-shop label {
    font-size: 24px;
}
.wrapper-login .group-shop label:before {
    font-family: 'icomoon';
}
.wrapper-login .group-shop label:before {
    content: "\e907";
    color: #dfe1e4;
}
.wrapper-login .btn {
    display: block;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
    margin-top: 48px;
}
.wrapper-login .btn-link {
    text-transform: none;
    letter-spacing: normal;
    font-size: 15px;
    padding: 3px;
    width: auto;
    display: inline-block;
    margin-bottom: 0px;
    color: #3f4245;
}

.wrapper-login .logo img {
    height: 50px;
}

.popup-messages .modal-dialog .modal-footer button {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    color: #007aff;;
}

.popup-messages .modal-dialog .modal-footer button:focus {
    outline: 0;
}

.wrapper-login .form-login input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

select option[disabled] { color: #ccc;}

.powered-by-title {
    color: #595b5d;
    margin-top: 60px;
    text-align: center;
}

.powered-by-title .magestore-link {
    color: inherit;
}

.logout-actions:before,
.logout-actions:after {
    content: "";
    display: table;
}
.logout-actions:after {
    clear: both;
}
.logout-actions a {
    display: block;
    width: 50%;
    float: left;
    line-height: 57px;
}
.logout-actions a + a {
    border-left: solid 1px #e0e0e0;
}
.wrapper-switch-device {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f8f8f8;
}
.wrapper-switch-device .block-title {
    height: 66px;
    background-color: #ffffff;
    border-bottom: solid 1px #dfe1e4;
}
.wrapper-switch-device .block-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
}
.wrapper-switch-device .block-bottom .btn {
    height: 57px;
    display: inline-block;
    border-radius: 5px;
    padding: 0 35px;
    font-size: 19px;
    letter-spacing: 0.3px;
    margin: 0 10px;
}
.wrapper-switch-device .img-switchdevice {
    color: #3f4245;
    line-height: 1.67;
    letter-spacing: 0.2px;
    font-size: 15px;
    position: relative;
    padding: 40px 0;
}
.wrapper-switch-device .img-switchdevice:before {
    content: "";
    display: block;
    width: 192px;
    height: 102px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('./images/switchdevice.svg');
    margin: 0 auto 60px;
}
.wrapper-switch-device .block-content {
    height: calc(100% -  163px);
    height: -moz-calc(100% -  163px);
    height: -webkit-calc(100% -  163px);
    height: -o-calc(100% -  163px);
}
.wrapper-switch-device .block-content .scroll-content {
    min-height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
