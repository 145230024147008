.category-product-container {
    text-align: left;
    float: left;
    width: 140px;
    position: relative;
    font-size: 15px;
    letter-spacing: 0.2px;
    z-index: 20;
  }
  .category-product-container .category-results {
    padding-right: 20px;
    background-image: url('./images/more.svg');
    background-repeat: no-repeat;
    background-position: right center;
    cursor: pointer;
    text-align: center;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  .category-top {
    text-align: center;
    background-color: #f7f7f7;
    line-height: 64px;
    background-position: left 17px center;
    background-repeat: no-repeat;
    background-image: url('./images/gird.svg');
    padding: 0 43px;
    border-radius: 15px 15px 0 0;
    border-bottom: solid 1px #dfe1e4;
    position: relative;
    z-index: 10;
    margin-bottom: -1px;
    cursor: pointer;
  }
  .category-top:hover div {
    color: #1d63dc;
  }
  .category-top div {
    color: #1d63dc !important;
  }
  .category-product-container div {
    color: #1d1d1d;
  }
  .category-product-container a:hover {
    color: #4a90e2;
  }
  .category-backdrop {
    visibility: hidden;
    -webkit-transition: 0.45s;
    -o-transition: 0.45s;
    transition: 0.45s;
  }
  .category-backdrop.in {
    visibility: visible;
    -webkit-transition: 0.45s;
    -o-transition: 0.45s;
    transition: 0.45s;
  }
  .dl-trigger:before {
    opacity: 0;
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    /*-webkit-transition: 0.45s;*/
    /*-o-transition: 0.45s;*/
    /*transition: 0.45s;*/
  }
  .dl-trigger.dl-active:before {
    visibility: visible;
    /*-webkit-transition: 0.45s;*/
    /*-o-transition: 0.45s;*/
    /*transition: 0.45s;*/
    opacity: 1;
  }
  .dl-trigger.dl-active + .category-drop {
    opacity: 1;
    visibility: visible !important;
    margin-top: 18px;
  }
  .dl-trigger.dl-active + .category-drop .category-top {
    visibility: visible !important;
    pointer-events: auto !important;
  }
  .popup-drop-category {
    position: absolute;
    top: 0;
    bottom: auto;
    height: 100%
  }
  .popup-drop-category .modal-dialog {
    min-height: 500px;
    max-height: calc(100% - 145px);
    max-height: -o-calc(100% - 145px);
    width: 342px;
    border-radius: 15px;
    right: 0;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    margin: 80px 400px;
  }
  @media (max-width: 1024px) {
    .popup-drop-category .modal-dialog {
      margin: 80px 300px;
    }
  }
  .popup-drop-category .modal-content {
    padding: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    position: static;
    height: 100%;
    border: none;
    display: inline;
  }
  .popup-drop-category .category-drop .category-top{
    visibility: visible !important;
  }
  .popup-drop-category .category-drop{
    margin-top: 0 !important;
    top: 0px !important;
  }
  .popup-drop-category .category-drop:before{
    visibility: visible !important;
  }
  .category-drop:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 12px 11px;
    border-color: transparent transparent #f7f7f7 transparent;
    position: absolute;
    top: -12px;
    left: 50%;
    margin-left: -11px;
    z-index: 200;
  }
   .dl-menuwrapper {
    height: calc(100% -  70px);
    height: -moz-calc(100% -  70px);
    height: -webkit-calc(100% -  70px);
    height: -o-calc(100% -  70px);
    overflow: hidden;
  }
   .dl-menuwrapper .dl-menuopen {
    max-height: 100%;
    overflow-y: auto;
  }
   .dl-menuwrapper .dl-menu {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    max-height: 100%;
    overflow-y: auto;
  }
  .dl-menuwrapper {
    width: 100%;
    float: left;
    position: relative;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-perspective-origin: 50% 200%;
    perspective-origin: 50% 200%;
  }
  .dl-menuwrapper:first-child {
    margin-right: 100px;
  }
  .dl-menuwrapper ul {
    padding: 0;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-color: #fff;
    border-radius: 0 0 15px 15px;
    max-height: 100%;
  }
  .dl-menuwrapper li {
    position: relative;
  }
  .dl-menuwrapper li div {
    display: block;
    position: relative;
    padding: 15px 60px 15px 30px;
    line-height: 30px;
    color: #1d1d1d;
    outline: none;
    border-top: solid 1px #dfe1e4;
    cursor: pointer;
  }
  .dl-menuwrapper li.dl-back > div {
    background-color: #f7f7f7;
    display: block;
    text-align: center;
    font-weight: 500;
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
  }
  .dl-menuwrapper li.dl-back > div:before {
    font-family: 'icomoon';
  }
  .dl-menuwrapper li.dl-back > div:before {
    content: "\e900";
    color: #66a0e5;
  }
  .dl-menuwrapper li.dl-back > div:before {
    font-size: 24px;
    position: absolute;
    left: 15px;
  }
  
  
  
  .dl-menuwrapper li.menu-label {
    pointer-events: none;
  }
  .dl-menuwrapper li.menu-label > div {
    background-color: #f7f7f7;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
  }
  .dl-menuwrapper li .toggle-submenu {
    display: block;
    width: 60px;
    height: 44px;
    line-height: 44px;
    margin-top: 8px;
    bottom: 8px;
    border-left: solid 1px #dfe1e4;
    cursor: pointer;
    z-index: 10;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('./images/expend.svg');
    position: absolute;
    top: 0;
    right: 0;
  }
  .dl-menuwrapper li .toggle-submenu span {
    display: none;
  }
  .dl-menuwrapper li > div:not(:only-child) {
    position: relative;
  }
  .dl-menuwrapper li > div:not(:only-child):before {
    content: "";
  }
  .dl-menuwrapper li .dl-submenu {
    display: none;
  }
  .dl-menuwrapper .dl-menu {
    margin: 0px 0 0 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0s !important;
    -o-transition: 0s !important;
    transition: 0s !important;
  }
  .dl-menuwrapper .dl-menu.dl-menu-toggle {
    transition: all 0.3s ease;
  }
  .dl-menuwrapper .dl-menu.dl-menuopen {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
  }
  .dl-menuwrapper .dl-menu.dl-menuopen ul {
    overflow: "hidden";
    outline: "none";
  }
  .dl-menuwrapper .dl-menu.dl-subview li {
    display: none;
  }
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > div,
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subview > div,
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > span,
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subview > span {
    display: none;
  }
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subview,
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subview .dl-submenu,
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen,
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
    display: block;
  }
  .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > .scroll-content > li {
    display: block;
  }
  .dl-menuwrapper .dl-submenu {
    max-height: 100%;
  }
  .dl-menuwrapper > .dl-submenu {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0;
    margin: 0;
  }
  .dl-menuwrapper > .dl-submenu.dl-animate-in-1,
  .dl-menuwrapper > .dl-submenu.dl-animate-in-2,
  .dl-menuwrapper > .dl-submenu.dl-animate-in-3,
  .dl-menuwrapper > .dl-submenu.dl-animate-in-4,
  .dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
    -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
    animation: SubMenuAnimIn2 0.3s ease-in-out;
  }
  .dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
    -webkit-animation: SubMenuAnimOut1 0.4s ease;
    animation: SubMenuAnimOut1 0.4s ease;
  }
  .dl-menuwrapper > .dl-submenu.dl-animate-out-1,
  .dl-menuwrapper > .dl-submenu.dl-animate-out-2,
  .dl-menuwrapper > .dl-submenu.dl-animate-out-3,
  .dl-menuwrapper > .dl-submenu.dl-animate-out-4,
  .dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
    -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
    animation: SubMenuAnimOut2 0.3s ease-in-out;
  }
  .no-js .dl-menuwrapper .dl-menu {
    position: relative;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  .no-js .dl-menuwrapper li .dl-submenu {
    display: block;
  }
  .no-js .dl-menuwrapper li.dl-back {
    display: none;
  }
  .dl-menuwrapper .simplebar-track {
    visibility: hidden !important;
  }
  .dl-menuwrapper .dl-menuopen > .simplebar-track {
    visibility: visible !important;
  }
  .dl-menu.dl-animate-out-2,
  .dl-menu.dl-animate-out-1,
  .dl-menu.dl-animate-out-3,
  .dl-menu.dl-animate-out-4,
  .dl-menu.dl-animate-out-5 {
    -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
    animation: MenuAnimOut2 0.3s ease-in-out;
  }
  .dl-menu.dl-animate-in-2,
  .dl-menu.dl-animate-in-1,
  .dl-menu.dl-animate-in-3,
  .dl-menu.dl-animate-in-4,
  .dl-menu.dl-animate-in-5 {
    -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
    animation: MenuAnimIn2 0.3s ease-in-out;
  }
  @-webkit-keyframes MenuAnimOut2 {
    100% {
      -webkit-transform: translateX(-100%);
      opacity: 0;
    }
  }
  @keyframes MenuAnimOut2 {
    100% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  @-webkit-keyframes MenuAnimIn2 {
    0% {
      -webkit-transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes MenuAnimIn2 {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes SubMenuAnimIn2 {
    0% {
      -webkit-transform: translateX(100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes SubMenuAnimIn2 {
    0% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes SubMenuAnimOut2 {
    0% {
      -webkit-transform: translateX(0%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
      opacity: 0;
    }
  }
  @keyframes SubMenuAnimOut2 {
    0% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 0;
    }
  }
  .hidden {
    display: none !important;
  }
  
  .menu-label-anchor {
    padding: 15
  }

  .dl-subview .selected-menu-label-anchor {
    color: #007aff !important
  }
