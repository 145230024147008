.order-cancel-confirm-text {
    font-weight: 700;
    margin-top: 24px;
    font-size: 16px;
}

#order-cancel-modal-title {
    font-weight: 700 !important;
    font-size: 18px !important;
}

.popup-edit-customer .form-control.disable-box-shadow {
    box-shadow: none !important;
}

#custom-switch {
    height: 30px;
    width: 48px;
    margin-right: 24px;
    cursor: pointer;
}

.popup-cancel-order .box-comfirm-switch {
    display: flex;
    justify-content: space-between;
    height: 48px;
    background-color: #fff;
    padding: 2px 0;
    border: 1px solid #e0dede;
}

.popup-cancel-order .box-comfirm-switch .order-cancel-restock-confirm-text {
    display: block;
    padding-top: 12px;
    font-weight: 600;
    font-size: 16px;
}

.popup-cancel-order .actions-bottom a.disable {
    color: #aaa8a8;
    cursor: not-allowed;
}